import React from 'react';

import { BundleFeatureType } from 'types/types';

import { Box, Text } from 'rebass';
import { theme } from '../../../../layouts/main.layout';
import { featureWrapper } from './BundleFeature.css';

interface BundleFeatureProps {
  feature: BundleFeatureType;
}

const TerritoryFeature: React.FC<BundleFeatureProps> = (props: BundleFeatureProps) => {
  const { feature } = props;
  return (
    <Box css={featureWrapper} fontSize="16px">
      <Text fontWeight={theme.fontWeights.extraLight}>{feature.description} </Text>
    </Box>
  );
};

export default TerritoryFeature;
