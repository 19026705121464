import React from 'react';

import { Box, Text } from 'rebass';

import { bundleWrapper, featureCardHeading } from './featureCard.css';

import BundleFeature from '../bundleFeature/BundleFeature.component';
import TerritoryFeature from '../bundleFeature/TerritoryFeature.component';
import { FlexCol, FlexWrapper } from '../../../../global/new-global-components';

interface FeatureCardProps {
  featureHeading: string;
  featuresArray: any;
  territoryFeature?: any;
  renderTeritoryFeature?: boolean;
}

const FeatureCard: React.FC<FeatureCardProps> = (props: FeatureCardProps) => {
  const { featureHeading, featuresArray, territoryFeature, renderTeritoryFeature } = props;

  const renderBundleFeature = (feature: any) => {
    if (!territoryFeature && featureHeading === feature.attributes.meta.category_title) {
      return (
        <BundleFeature
          key={feature.id}
          feature={{
            noFeatures: feature.attributes.noFeatures,
            title: feature.attributes.title,
            description: feature.attributes.description,
          }}
        />
      );
    } else {
      if (featureHeading === feature.attributes.meta.category_title && feature.attributes.title !== territoryFeature.attributes.title) {
        return (
          <BundleFeature
            key={feature.id}
            feature={{
              noFeatures: feature.attributes.noFeatures,
              title: feature.attributes.title,
              description: feature.attributes.description,
            }}
          />
        );
      }
    }
  };
  return (
    <Box css={bundleWrapper}>
      <Box style={{}}>
        <Text css={featureCardHeading}>{featureHeading}</Text>
      </Box>
      <FlexWrapper className="teritory-feature-flex-wrapper" flexWrap="wrap">
        {!!renderTeritoryFeature && (
          <FlexCol width="50%">
            <TerritoryFeature
              feature={{
                noFeatures: territoryFeature.attributes.noFeatures,
                title: territoryFeature.attributes.title,
                description: territoryFeature.attributes.description,
              }}
            />
          </FlexCol>
        )}
        {featuresArray.map((feature: any) => {
          return renderBundleFeature(feature);
        })}
      </FlexWrapper>
    </Box>
  );
};

export default FeatureCard;
