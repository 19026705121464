import styled from '@emotion/styled';

export const BundleDetailsPageWrapper = styled.div`
  .mobile-show {
    display: none;
  }
  .price-wrapper span {
    font-size: 32px;
  }

  .sticky-wrapper {
    visibility: hidden;
    transition: all 0.2s;
    opacity: 0;
    position: fixed;
    top: 128px;
    width: 100%;
    height: auto;
    z-index: 9;
  }

  .territorialInfoWrapper {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  .priceWrapper {
    padding: 10px 20px;
  }

  @media screen and (min-width: 769px) {
    .sticky-wrapper {
      top: 90px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 820px) {
    .priceWrapper {
      width: 308px;
      padding: 6px 20px;
    }
  }
  @media screen and (min-width: 821px) {
    .priceWrapper {
      padding: 6px 20px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 780px) {
    .territory-feature-wrapper {
      padding: 0px 10px 0px 10px;
    }

    .sticky-wrapper {
      padding: 20px;
    }
  }

  @media screen and (min-width: 800px) and (max-width: 899px) {
    .sticky-wrapper {
      padding: 20px;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1400px) {
    .territory-feature-wrapper {
      padding: 0px 20px 0px 20px;
    }

    .sticky-wrapper {
      padding: 20px;
    }
  }

  @media screen and (min-width: 900px) and (max-width: 1000px) {
    .bundle-details-page {
      .innerWrapperStyle {
        padding: 90px 50px 0px 50px;
      }
    }
  }

  @media screen and (min-width: 1000px) {
    .sticky-wrapper {
      top: 94px;
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1400px) {
    .bundle-details-page {
      .innerWrapperStyle {
        padding: 90px 80px 0px 80px;
      }
    }
  }

  @media screen and (min-width: 1400px) {
    h1 {
      font-size: 44px;
    }
    /* h2 {
      font-size: 22px;
    } */

    .europe,
    .europe-max,
    .domestic {
      padding: 80px 100px 0px 100px;
    }

    .sticky-wrapper {
      top: 94px;
      &.europe,
      &.europe-max,
      &.domestic {
        padding: 20px 100px 20px 100px;
      }
    }

    .territory-feature-wrapper {
      padding: 0px 100px 0px 100px;
    }
  }

  @media screen and (min-width: 1500px) {
    h1 {
      font-size: 44px;
    }
    /* h2 {
      font-size: 22px;
    } */

    .europe,
    .europe-max,
    .domestic {
      padding: 80px 150px 0px 150px;
    }

    .sticky-wrapper {
      &.europe,
      &.europe-max,
      &.domestic {
        padding: 20px 150px 20px 150px;
      }
    }

    .territory-feature-wrapper {
      padding: 0px 150px 0px 150px;
    }
  }

  @media screen and (min-width: 1600px) {
    h1 {
      font-size: 44px;
    }
    /* h2 {
      font-size: 22px;
    } */

    .europe,
    .europe-max,
    .domestic {
      padding: 80px 250px 0px 250px;
    }

    .sticky-wrapper {
      &.europe,
      &.europe-max,
      &.domestic {
        padding: 30px 250px 30px 250px;
      }
    }

    .territory-feature-wrapper {
      padding: 0px 250px 0px 250px;
    }

    .priceWrapper {
      padding: 10px 20px;
    }
  }

  @media screen and (min-width: 2000px) {
    h1 {
      font-size: 44px;
    }
    /* h2 {
      font-size: 22px;
    } */

    .europe,
    .europe-max,
    .domestic {
      padding: 80px 400px 0px 400px;
    }

    .sticky-wrapper {
      &.europe,
      &.europe-max,
      &.domestic {
        padding: 40px 400px 40px 400px;
      }
    }

    .territory-feature-wrapper {
      padding: 0px 400px 0px 400px;
    }
  }

  @media screen and (min-width: 2200px) {
    .priceWrapper {
      padding: 15px 20px;
    }
  }

  @media screen and (max-width: 768px) {
    .mobile-show {
      display: flex;
      align-items: center;
    }
    .bundle-details-page p {
      text-align: center;
    }
    .mobile-stay-flex {
      border-radius: 22px;
      overflow: hidden;
      flex-wrap: nowrap;
      margin-top: 20px;

      button {
        margin: 0 !important;
        border-radius: 0;
        height: 56px;
        div {
          flex-direction: row;
        }
      }
    }
    .price-wrapper-outer {
      margin: 0;
    }
    h1 {
      font-size: 34px;
    }
    .europe,
    .europe-max,
    .domestic {
      height: 370px;
      padding: 40px 20px 0px 20px;
      h1 {
        text-align: center;
      }
      h2 {
        text-align: center;
        font-size: 14px !important;
      }
      .flex-col {
        width: 100%;
      }
      .price-wrapper-outer {
        max-width: auto;
        display: inline-block;
        padding: 10px;
        border-radius: 0;
      }
      .price-wrapper {
        display: flex;
        align-items: center;
        font-size: 24px;
        span {
          font-size: 16px;
          margin-left: 5px;
        }
      }
      .mobile-hidden {
        display: none;
      }
      .alert {
        display: flex;
        border-radius: 10px;
        padding: 15px;
        flex-wrap: initial;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
      }
      .alert-text {
        font-size: 12px;
      }
    }
    .territory-feature-wrapper {
      padding: 0px 20px 0px 20px;
    }
    .mobile-hidden {
      display: none;
    }

    .mobile-white-background {
      background-color: white;
    }
    .flex-col {
      width: 100%;
      margin-top: 20px;
      button {
        width: 50%;
        margin: 5px auto;
      }
    }
    .how-it-works-card {
      img {
        margin: 10px auto;
      }
    }

    .territorialInfoWrapper {
      padding: 15px;
      justify-content: flex-start;

      img {
        width: 32px;
      }

      .feature-title {
        font-size: 1rem;
      }
    }

    .sticky-wrapper {
      visibility: hidden;
      transition: all 0.2s;
      opacity: 0;
      position: fixed;
      top: 96px;
      width: 100%;
      height: auto;
      z-index: 9;
      p {
        text-align: center;
      }
      .price-wrapper-outer {
        max-width: auto;
      }

      .buttons-flex-wrapper {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 30px;

        .price-wrapper {
          align-items: center;
        }

        button {
          margin: 0;
          border-radius: 0;
          height: 56px;
          border-top-right-radius: 22px;
          border-bottom-right-radius: 22px;

          div {
            display: flex;
            flex-direction: row;
          }
        }
      }
    }
  }
`;
