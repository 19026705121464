import React, { useEffect, useRef, useState } from 'react';

import { Box, Text } from 'rebass';

import { useTranslation } from 'react-i18next';
import FeatureCard from './components/featureCard/featureCard.component';
import { Link } from 'gatsby';

import { navigateTo } from '../../components/functional/Navigate';
import { useRecoilState } from 'recoil';
import { purchasePackage } from '../../shared-state/main';

import { grayLink, wrapper } from './bundleDetails.css';
import { promoBundleKeyNames } from '../../configs/bundles';
import { COUNTRY } from '../../configs/env';
import { HomePageLayout } from '../../layouts/HomePage.layout';
import { VOZZiWebsitePage } from '../../types/VozziWebsitePage.type';
import { BundleDetailsPageWrapper } from './new-bundle-details.styles';
import BundleNote from '../../components/layout/bundleNote/bundleNote.component';
import { BigTitle, FlexCol, FlexWrapper, Img, SimpleButton, Text as Txt, Title, Wrapper } from '../../global/new-global-components';
import { Card } from '../bundles/sections/new_how_it_works_section/how_it_works_single_card';
import { GetAppSection } from '../home/new_get_app_section/getapp.section';
import { isBrowser } from 'react-device-detect';
import { bundleWrapper, featureCardHeading } from './components/featureCard/featureCard.css';
import { LanguageGuard } from '../../components/layout/LanguageGuard/LanguageGuard';

const PackagesPage: VOZZiWebsitePage = (props) => {
  const { oneBundle, territoryFeature, vozziTitles, domesticTitles } = props.pageContext;

  const { t, i18n } = useTranslation();
  const [, setBundleDetails] = useRecoilState<any>(purchasePackage);
  const sticky = useRef<any>(null);
  const [className, setClassName] = useState<'domestic' | 'europe' | 'europe-max'>('domestic');
  const [, setBundleBranchLink] = useState('https://vozzi.app.link/');

  useEffect(() => {
    const { keyName } = oneBundle.attributes;

    // Class name for background
    if (keyName.includes('evropa') || keyName.includes('europa')) {
      setClassName('europe');
    }
    if (keyName.includes('evropa-max') || keyName.includes('europa-max') || keyName.includes('flex')) {
      setClassName('europe-max');
    }

    // Branch link
    switch (keyName) {
      case 'vozzi-rs-2022': {
        setBundleBranchLink('https://vozzi.app.link/DlDzD8ZGDnb');
        break;
      }
      case 'vozzi-evropa': {
        setBundleBranchLink('https://vozzi.app.link/AAHsZM4GDnb');
        break;
      }
      case 'vozzi-evropa-max': {
        setBundleBranchLink('https://vozzi.app.link/yrrEzM8GDnb');
        break;
      }
      default: {
        setBundleBranchLink('https://vozzi.app.link/Vkw1LWwMDnb');
        break;
      }
    }
  }, [oneBundle]);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('scroll', () => {
        if (window.innerWidth > 768) {
          if (window.scrollY > 500) {
            if (sticky.current) {
              sticky.current.style.visibility = 'visible';
              sticky.current.style.opacity = 1;
            }
          } else {
            if (sticky.current) {
              sticky.current.style.visibility = 'hidden';
              sticky.current.style.opacity = 0;
            }
          }
        } else {
          if (window.scrollY > 300) {
            if (sticky.current) {
              sticky.current.style.visibility = 'visible';
              sticky.current.style.opacity = 1;
            }
          } else {
            if (sticky.current) {
              sticky.current.style.visibility = 'hidden';
              sticky.current.style.opacity = 0;
            }
          }
        }
      });
    }
  }, []);

  const LANG = i18n.language;

  const metaOgImg: any = {
    rs: {
      sr: '/img/meta-img/packages-webshop-meta-og-2-0-new.jpg',
      en: 'https://rs.vozzi.app/img/meta-img/packages-webshop-meta-og-eng-2-0.jpg',
    },
    me: {
      me: '/img/meta-img/packages-webshop-meta-og-2-0-new.jpg',
      en: 'https://me.vozzi.app/img/meta-img/packages-webshop-meta-og-eng-2-0.jpg',
    },
    mk: {
      mk: '/img/meta-img/packages-webshop-meta-og-2-0-new.jpg',
    },
    ba: {
      bs: '/img/meta-img/packages-webshop-meta-og-2-0-new.jpg',
    },
    si: {
      sl: '/img/meta-img/packages-webshop-meta-og-2-0-new.jpg',
    },
    ro: {
      ro: '/img/meta-img/packages-webshop-meta-og-2-0-new.jpg',
    },
    bg: {
      bg: '/img/meta-img/packages-webshop-meta-og-2-0-new.jpg',
    },
  };

  const meta = {
    title: oneBundle.attributes.meta.meta_title ? oneBundle.attributes.meta.meta_title : t('meta_defaultTitle'),
    description: oneBundle.attributes.meta.meta_description ? oneBundle.attributes.meta.meta_description : t('meta_eu1Description'),
  };

  const og = {
    image: metaOgImg[COUNTRY][LANG],
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const possibilitesCards = () => {
    const domesticBundleKeys = [
      'vozzi-rs-2022',
      'vozzi-cg-2022',
      'vozzi-mkd',
      'vozzi-bih-2022',
      'vozzi-si-2022',
      'vozzi-rom',
      'vozzi-bg-2022',
    ];

    const europeBundleKeys = [
      'vozzi-evropa',
      'vozzi-evropa-cg',
      'vozzi-evropa-mk',
      'vozzi-evropa-bih',
      'vozzi-evropa-si',
      'vozzi-evropa-rom',
      'vozzi-evropa-bg',
    ];
    const europeMaxBundleKeys = [
      'vozzi-evropa-max',
      'vozzi-evropa-max-cg',
      'vozzi-evropa-max-mk',
      'vozzi-evropa-max-bih',
      'vozzi-evropa-max-si',
      'vozzi-evropa-max-rom',
      'vozzi-evropa-max-bg',
    ];
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const newDescFirstCard = () => {
      if (domesticBundleKeys.includes(oneBundle.attributes.keyName)) return t('packages_GiftSectionCard1Details');
      if (europeBundleKeys.includes(oneBundle.attributes.keyName)) return t('packages_GiftSectionCard2Details');
      if (europeMaxBundleKeys.includes(oneBundle.attributes.keyName)) return t('packages_GiftSectionCard3Details');
      return '';
    };

    return [
      // {
      //   icon: '/vectors/gift.svg',
      //   title: t('packageDetails_PossibilitesFirstCardTitle'),
      //   description: newDescFirstCard(),
      //   height: 70,
      // },
      {
        icon: '/vectors/motor.svg',
        title: t('packageDetails_PossibilitesSecondCardTitle'),
        description: t('packageDetails_PossibilitesSecondCardDescription'),
        height: 70,
      },
      {
        icon: '/vectors/upgrade.svg',
        title: t('packageDetails_PossibilitesThirdCardTitle'),
        description: t('packageDetails_PossibilitesThirdCardDescription'),
        height: 70,
      },
    ];
  };

  /* Background images for another season are in the same folder as the current ones, just look for their counterparts
   * with "alt-season" appended to their name */

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const calculatedBonusPoints = (bundle: any) => {
    const {
      attributes: { keyName, price },
    } = bundle;

    let bonusPoints: number;

    switch (keyName) {
      case 'vozzi-rs-2022':
        bonusPoints = price * 0.1;

        break;
      case 'vozzi-evropa':
        bonusPoints = price * 0.15;

        break;
      case 'vozzi-evropa-max':
        bonusPoints = price * 0.2;

        break;
      default:
        bonusPoints = 0;
    }

    return bonusPoints.toFixed(2);
  };

  return (
    <HomePageLayout fullWidth={true} meta={meta} og={og}>
      <BundleDetailsPageWrapper css={wrapper}>
        <Wrapper ref={sticky} className={`${className} sticky-wrapper`}>
          <FlexWrapper justifyContent="space-between" alignItems="center">
            <FlexCol className="flex-col" width="50%">
              <BigTitle color="white" fontWeight="800" textAlign="left" fontSize="75px">
                {oneBundle.attributes.name}
              </BigTitle>
              <Txt textAlign="left" fontWeight="400" color="white" fontSize="24px">
                {oneBundle.attributes.meta.topinfo_title}
              </Txt>
            </FlexCol>
            <FlexCol borderRadius="22px" overflow="hidden" className="flex-col" width="50%">
              <FlexWrapper overflow="hidden" className="buttons-flex-wrapper" flexWrap="nowrap" justifyContent="flex-end">
                <FlexWrapper
                  className="priceWrapper"
                  alignItems="center"
                  maxWidth="250px"
                  borderTopLeftRadius="22px"
                  borderBottomLeftRadius="22px"
                  backgroundColor="#1E1E23"
                  overflow="hidden"
                >
                  <Text className="price-wrapper" color="white" fontSize="32px" fontWeight="800">
                    {oneBundle.attributes.price} <span style={{ fontSize: 14 }}>{oneBundle.attributes.currency.displayName}</span>
                  </Text>
                </FlexWrapper>
                <SimpleButton
                  borderRadius="0px"
                  onClick={() => {
                    setBundleDetails({
                      bundle: oneBundle,
                      packageName: oneBundle.attributes.name,
                      packagePrice: oneBundle.attributes.price,
                      currency: oneBundle.attributes.currency.displayName,
                    });

                    navigateTo(t('link_webShopDetails'));
                  }}
                  maxWidth="200px"
                  backgroundColor="white"
                  color="#181818"
                >
                  <FlexWrapper justifyContent="center">
                    <Img style={{ marginRight: 5 }} src="/vectors/shopping-cart.svg" />
                    {t('btn_BuyPackage')}
                  </FlexWrapper>
                </SimpleButton>
              </FlexWrapper>
            </FlexCol>
          </FlexWrapper>
        </Wrapper>
        <Wrapper css={wrapper} className="bundle-details-page">
          <Wrapper className={`${className} innerWrapperStyle`} height="500px">
            <FlexWrapper justifyContent="space-between">
              <FlexCol className="flex-col" width="50%">
                <BigTitle color="white" fontWeight="800" textAlign="left" fontSize="75px">
                  {oneBundle.attributes.name}
                </BigTitle>
                <Txt textAlign="left" fontWeight="400" color="white" fontSize="24px">
                  {oneBundle.attributes.meta.topinfo_title}
                </Txt>
                <FlexWrapper className="mobile-stay-flex mobile-white-background">
                  <Wrapper
                    className="price-wrapper-outer"
                    padding="20px 30px"
                    marginTop="20px"
                    maxWidth="250px"
                    borderRadius="20px"
                    backgroundColor="#1E1E23"
                  >
                    <Text className="price-wrapper" color="white" fontSize="40px" fontWeight="800">
                      {oneBundle.attributes.price}
                      <span>{oneBundle.attributes.currency.displayName}</span>
                    </Text>
                  </Wrapper>
                  <SimpleButton
                    borderRadius="22px"
                    onClick={() => {
                      setBundleDetails({
                        bundle: oneBundle,
                        packageName: oneBundle.attributes.name,
                        packagePrice: oneBundle.attributes.price,
                        currency: oneBundle.attributes.currency.displayName,
                      });

                      navigateTo(t('link_webShopDetails'));
                    }}
                    className="mobile-show"
                    backgroundColor="white"
                    color="#181818"
                    marginBottom="15px"
                  >
                    <FlexWrapper justifyContent="center">
                      <Img style={{ marginRight: 5 }} src="/vectors/shopping-cart.svg" />
                      {t('btn_BuyPackage')}
                    </FlexWrapper>
                  </SimpleButton>
                </FlexWrapper>
              </FlexCol>
              <FlexCol className="flex-col" width="336px">
                <SimpleButton
                  borderRadius="22px"
                  onClick={() => {
                    setBundleDetails({
                      bundle: oneBundle,
                      packageName: oneBundle.attributes.name,
                      packagePrice: oneBundle.attributes.price,
                      currency: oneBundle.attributes.currency.displayName,
                    });

                    navigateTo(t('link_webShopDetails'));
                  }}
                  className="mobile-hidden"
                  backgroundColor="white"
                  color="#181818"
                  marginBottom="15px"
                >
                  <FlexWrapper justifyContent="center">
                    <Img style={{ marginRight: 5 }} src="/vectors/shopping-cart.svg" />
                    {t('btn_BuyPackage')}
                  </FlexWrapper>
                </SimpleButton>
                <FlexWrapper alignItems="flex-start" className="alert" padding="25px" overflow="hidden" borderRadius="22px">
                  <Img style={{ marginRight: 5 }} src="/img/alert-gray.svg" />
                  <Text className="alert-text">{t('purchasePageGeolocationEnableText')}</Text>
                </FlexWrapper>
              </FlexCol>
            </FlexWrapper>
          </Wrapper>

          <Wrapper backgroundColor="transparent">
            <Wrapper className="territory-feature-wrapper" backgroundColor="#F4F4F4">
              <Wrapper>
                {territoryFeature && (
                  <Wrapper backgroundColor="transparent" style={{ marginBottom: '80px' }}>
                    <Box css={bundleWrapper}>
                      <Text mb={-20} css={featureCardHeading}>
                        {territoryFeature.attributes.meta.category_title}
                      </Text>
                      <div className="territorialInfoWrapper">
                        <Img src="/vectors/globe.svg" style={{ marginRight: 20 }} />
                        <div>
                          <Text
                            className="feature-title"
                            fontSize="1.5rem"
                            color="#1E1E23"
                            marginBottom="15px"
                            lineHeight="125%"
                            fontWeight="700"
                          >
                            {oneBundle.attributes.description}
                          </Text>

                          <Text style={{ maxWidth: '90%' }} fontSize=".875rem" fontWeight={200}>
                            {territoryFeature.attributes.description}{' '}
                          </Text>
                        </div>
                      </div>
                    </Box>
                  </Wrapper>
                )}
                {/* Domestic bundle has only one category title */}
                {className === 'domestic' ? (
                  <FeatureCard
                    key={domesticTitles[0]}
                    featureHeading={domesticTitles[0]}
                    featuresArray={oneBundle.features.data}
                    territoryFeature={territoryFeature}
                  />
                ) : null}
                {/* Ino bundle can have multiple titles */}
                {className !== 'domestic'
                  ? vozziTitles
                      .reverse()
                      .map((title: string) => (
                        <FeatureCard
                          key={title}
                          featureHeading={title}
                          featuresArray={oneBundle.features.data}
                          territoryFeature={territoryFeature}
                        />
                      ))
                  : null}
              </Wrapper>
              <Wrapper>
                <Text mb="36px" textAlign="center" color="rgba(30, 30, 35, 0.3)" fontSize="14px" fontWeight="400">
                  {t('packageDetails_MoreInformation')}
                  <Link to={t('link_termsAndConditions')} css={grayLink}>
                    {t('packageDetails_Terms')}
                  </Link>
                </Text>

                <Wrapper maxWidth="1300px" margin="60px auto">
                  <Title maxWidth="700px" fontSize="40px" margin="0 auto">
                    {t('packageDetails_Possibilities')}
                  </Title>
                  <FlexWrapper className="bundle-possibilities-wrapper" justifyContent="center" flexWrap="wrap">
                    {possibilitesCards().map((card: any) => (
                      <Card key={card.title} card={card} />
                    ))}
                  </FlexWrapper>
                </Wrapper>

                <Wrapper className="mobile-hidden">
                  {oneBundle.attributes.keyName !== promoBundleKeyNames[COUNTRY] && (
                    <BundleNote noteType="warning" noteText={t('packageDetails_InfoOrange')} />
                  )}

                  {/*<LanguageGuard visible={[{ country: 'rs' }]}>*/}
                  {/*  <BundleNote*/}
                  {/*    passedIcon="/img/nis.svg"*/}
                  {/*    noteType="warning"*/}
                  {/*    noteText={`Kupovinom ovog paketa možete dobiti ${calculatedBonusPoints(*/}
                  {/*      oneBundle*/}
                  {/*    )} bonus poena na SNNP kartici. Broj SNNP kartice*/}
                  {/*  možete uneti na sledećem ekranu.`}*/}
                  {/*  />*/}
                  {/*</LanguageGuard>*/}

                  {/* <BundleNote noteType="info" noteText={t('bundles_DetailsPageUpgradeInfo')} /> */}
                </Wrapper>
              </Wrapper>
            </Wrapper>
          </Wrapper>
        </Wrapper>
        <GetAppSection style={{ padding: '60px 0px 60px 0px', backgroundColor: 'white', width: '100%' }} elementPosition={1} />
      </BundleDetailsPageWrapper>
    </HomePageLayout>
  );
};

export default PackagesPage;
