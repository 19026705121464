import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlexCol, Img, SmallTitle, Text, Wrapper } from '../../../../global/new-global-components';
import { COUNTRY } from '../../../../configs/env';

export const Card = (props: any) => {
  const { i18n } = useTranslation();
  const lang = i18n.language;
  const { title, icon, description, height } = props.card;

  const getWrapperInnerHeight = () => {
    switch (COUNTRY) {
      case 'ro':
        return '370px';
      default:
        return 'auto';
    }
  };

  return (
    <FlexCol className="how-it-works-card" width="375px">
      <Wrapper
        className="how-it-works-card-inner"
        width="355px"
        height={getWrapperInnerHeight()}
        marginTop="20px"
        textAlign="center"
        padding="45px 35px"
        borderRadius="20px"
        backgroundColor="#1E1E23"
      >
        <div style={{ height: '96px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Img
            style={{
              height: height ? height : 'auto',
              position: icon === '/vectors/12-months.svg' ? 'relative' : 'static',
              left: icon === '/vectors/12-months.svg' ? '12px' : '0px',
              margin: '0 auto',
            }}
            src={icon}
          />
        </div>
        <SmallTitle minHeight={lang === 'bg' ? '65px' : 'auto'} textAlign="center" color="white" marginBottom="20px" fontSize="24px">
          {title}
        </SmallTitle>
        <Text textAlign="center" minHeight="81px" color="white" fontSize="16px" opacity="0.7">
          {description}
        </Text>
      </Wrapper>
    </FlexCol>
  );
};
