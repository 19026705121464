import { css } from '@emotion/core';
import { theme } from '../../layouts/main.layout';

export const pageSection = css`
  padding-top: 64px;
`;
export const grayLink = css`
  color: ${theme.colors.primaryTextGray};
  text-decoration: underline;
`;

export const mobilePadding = css`
  @media screen and (max-width: 700px) {
    padding: 10px;
  }
`;

export const wrapper = css`
  .alert {
    border-radius: 22px;
  }
  background-color: transparent;
  .domestic {
    background-color: #f47e20;
    .alert {
      background-color: #ff9a49;
      .alert-text {
        color: #814515;
      }
    }
  }
  .europe {
    background-color: #767576;
    .alert {
      background-color: #979797;
      .alert-text {
        color: #3b3b3c;
      }
    }
  }
  .europe-max {
    background-color: #3b3b3c;
    .alert {
      background-color: #767576;
      .alert-text {
        color: #181818;
      }
    }
  }

  @media screen and (min-width: 780px) and (max-width: 900px) {
    .domestic {
      padding: 90px 50px 0px 50px;

      h1 {
        font-size: 36px;
      }
    }

    .europe,
    .europe-max {
      padding: 90px 50px 0px 50px;

      h1 {
        font-size: 36px;
      }
    }

    .territory-feature-wrapper {
      padding: 0px 50px 0px 50px;
    }

    .teritory-feature-flex-wrapper {
      flex-wrap: wrap;
      flex-direction: column;

      .teritory-feature-wrapper {
        width: 100%;
      }
    }

    .bundle-possibilities-wrapper {
      flex-direction: column;
    }
    .how-it-works-card-inner {
      margin-right: 0;
      width: 100%;
    }
  }
`;
