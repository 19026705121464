import { css } from '@emotion/core';

export const featureWrapper = css`
  letter-spacing: 0.28px;
`;

export const collWrapper: any = (height = 250) => css`
  max-height: ${height}px;
  height: ${height}px;
  & :nth-child(odd) {
    border-right: 1px solid #e9e9eb;
  }
  & :last-child {
    border-bottom: none;
  }
  border-top: 0.5px solid #e9e9eb;
  border-bottom: 0.5px solid #e9e9eb;

  @media screen and (min-width: 780px) and (max-width: 900px) {
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    height: auto;
    max-height: 100%;
    padding: 15px;
    .bundle-feature-flex-wrapper {
      flex-wrap: nowrap;
      flex-direction: row;

      img {
        height: 32px;
        width: 32px;
        margin-right: 10px;
      }
      .badge-orange {
        height: 25px;
        width: 25px;
        min-width: 25px;
        font-size: 12px;
      }
      .feature-title {
        font-size: 16px;
      }
    }
  }

  @media screen and (min-width: 900px) and (max-width: 970px) {
    max-height: 390px;
    height: 390px;
  }

  @media screen and (min-width: 970px) and (max-width: 1200px) {
    max-height: 320px;
    height: 320px;
  }
`;
