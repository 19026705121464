import React from 'react';

import { Box, Flex, Image, Text } from 'rebass';

import { useTranslation } from 'react-i18next';

import { bundleNoteWrapFlex } from './bundleNote.css';

interface BundleNoteProps {
  passedIcon?: string | null;
  noteType: 'warning' | 'info';
  noteText: string;
}

const noteTypeImage = {
  warning: 'alert-gray',
  info: 'info-gray',
};

const BundleNote: React.FC<BundleNoteProps> = (props: BundleNoteProps) => {
  const { passedIcon = null } = props;
  const { t } = useTranslation();

  return (
    <Box mb="8px" textAlign="center">
      <Flex css={bundleNoteWrapFlex} alignItems="center">
        <Image src={passedIcon ? passedIcon : `/img/${noteTypeImage[props.noteType]}.svg`} mr="7px" />
        <Text>{props.noteText}</Text>
      </Flex>
    </Box>
  );
};

export default BundleNote;
