import React from 'react';

import { BundleFeatureType } from 'types/types';

import { Text } from 'rebass';
import { theme } from '../../../../layouts/main.layout';
import { collWrapper } from './BundleFeature.css';
import { BadgeCircleOrange, FlexCol, FlexWrapper, Img, Wrapper } from '../../../../global/new-global-components';
import { COUNTRY } from '../../../../configs/env';

interface BundleFeatureProps {
  feature: BundleFeatureType;
}

const BundleFeature: React.FC<BundleFeatureProps> = (props: BundleFeatureProps) => {
  const { feature } = props;
  const getHeightOfWrapperByCountry = (): number => {
    switch (COUNTRY) {
      case 'ro':
        return 300;
      default:
        return 250;
    }
  };

  return (
    <FlexCol css={collWrapper(getHeightOfWrapperByCountry())} width="50%" padding="40px" fontSize="16px">
      <FlexWrapper className="bundle-feature-flex-wrapper" alignItems="flex-start">
        <Img src="/vectors/globe.svg" style={{ marginRight: 20 }} />
        <Wrapper>
          <Text className="feature-title" fontSize="24px" color="#1E1E23" marginBottom="15px" lineHeight="125%" fontWeight="700">
            {feature.title}
          </Text>

          <Text style={{ maxWidth: '90%' }} fontSize="14px" fontWeight={theme.fontWeights.extraLight}>
            {feature.description}{' '}
          </Text>
        </Wrapper>
        <BadgeCircleOrange className="badge-orange">x{feature.noFeatures}</BadgeCircleOrange>
      </FlexWrapper>
    </FlexCol>
  );
};

export default BundleFeature;
