import { css } from '@emotion/core';
import { theme } from '../../../layouts/main.layout';

export const bundleNoteWrapFlex = css`
  display: inline-flex;
  justify-content: center;
  height: 36px;
  margin: 10px 0;
  border: 1px solid transparent;
  background-color: ${theme.colors.backgroundLightGray};
  border-radius: 13px;
  padding: 10px 12px 10px 8px;
  @media screen and (max-width: 768px) {
    height: auto;
  }
`;
