import { css } from '@emotion/core';
import { theme } from '../../../../layouts/main.layout';

export const featureCard = css`
  min-height: 94px;
  width: 100%;
  object-fit: contain;
  border: 1px solid ${theme.colors.borderGray};
  border-radius: 8px;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    width: 97%;
  }
`;

export const featureCardHeading = css`
  padding: 3px 20px;
  background-color: #ffffff;
  color: #1e1e23;
  border-radius: 20px 20px 0 0;
  font-size: 40px;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    border-radius: 10px 10px 0 0;
    font-size: 20px;
    height: 65px;
  }
`;

export const featureCardContent = css`
  padding: 23px 20px 20px;
`;

export const collWrapper = css`
  border: 1px solid #e9e9eb;
`;

export const bundleWrapper = css`
  position: relative;
  background-color: white;
  box-shadow: 0px 35px 60px -25px rgba(30, 30, 35, 0.1);
  top: -40px !important;
  border-radius: 20px;
  margin-bottom: 80px;
  & :last-child {
    margin-bottom: 0;
  }

  @media screen and (max-width: 768px) {
    top: -65px;
  }

  @media screen and (min-width: 768px) {
    top: -130px !important;
  }
`;
