export const bundlesNotUpgradableKeyNames = {
  ba: 'vozzi-evropa-max-bih',
  mk: 'vozzi-evropa-max-mk',
  me: 'vozzi-evropa-max-cg',
  si: 'vozzi-evropa-max-si',
  bg: 'vozzi-evropa-max-bg',
  ro: 'vozzi-evropa-max-ro',
};

export const promoBundleKeyNames = {
  rs: 'vozzi-40-2022',
  ba: 'vozzi-40-bih-2022',
  mk: 'vozzi-40-mk-2022',
  me: 'vozzi-40-cg',
  si: 'vozzi-40-si-2022',
  bg: 'vozzi-start-bg',
  hu: 'vozzi-40-2022-hu',
  ro: 'vozzi-50-ro-2022',
};

export const territorialCoverageTitles = {
  rs: 'Teritorijalna pokrivenost',
  ro: 'Acoperire teritorială',
};

export const bundlesRoutes = {
  rs: {
    sr: 'pretplata-za-pomoc-na-putu',
    en: 'subscription-for-road-assistance',
  },
  me: {
    me: 'pretplata-za-pomoc-na-putu',
    en: 'subscription-for-road-assistance',
  },
  ba: {
    bs: 'pretplata-za-pomoc-na-putu',
  },
  mk: {
    mk: 'pretplata-za-pomos-na-pat',
  },
  si: {
    sl: 'naročnina-za-pomoč-na-cesti',
  },
  ro: {
    ro: 'abonament-pentru-asistenta-rutiera',
  },
  bg: {
    bg: 'abonament-za-pytna-pomosht',
  },
};

export const bundlesPackage = {
  rs: {
    sr: '-paket',
    en: '-package',
  },
  me: {
    me: '-paket',
    en: '-package',
  },
  ba: {
    bs: '-paket',
    en: '-package',
  },
  mk: {
    mk: '-paket',
  },
  si: {
    sl: '-paket',
  },
  bg: {
    bg: '-paket',
  },
  ro: {
    ro: '-pachet',
  },
};
